import img1 from "../assets/images/img/Asset 35.png";
import img2 from "../assets/images/img/Asset 34.png";
import img3 from "../assets/images/img/Asset 33.png";
import img4 from "../assets/images/img/Asset 32.png";
import img5 from "../assets/images/img/Asset 31.png";
import img6 from "../assets/images/img/Asset 30.png";

export const type_of_massage = [
  {
    id: 1,
    image: img1,
    title: "Couple Massage",
    duration: "60/90",
    description:
      "Couple massage allows two people to have a shared experience that can result in a closer bond. A couple may choose this time to chat and catch up with each other or simply relax and enjoy the experience together.",
  },
  {
    id: 1,
    image: img2,
    title: "Stress Massage",
    duration: "60/90",
    description:
      "Stress Massage focuses on realigning muscle and connective tissue using a method that is extremely beneficial for areas of the body suffering from tense and contracted muscles. A deep pressure massage performed slowly while observing the breath of the client results in emotional centering",
  },
  {
    id: 1,
    image: img3,
    title: "Signature Massage",
    duration: "90",
    description:
      "Signature Massage is probably one of our best spa treatments to ease tension and stress. This is full body massage therapy that includes face massage, foot reflexology, and head massage. Our experienced therapists will use aromatic oil and treat you with gentle, slow stroking movements which will result in an ultimate wellbeing experience. This massage includes Almond body scrub to exfoliates and hydrates your skin, smooth and soft.",
  },
  {
    id: 1,
    image: img4,
    title: "Swedish Massage",
    duration: "60/90",
    description:
      "Swedish massage therapy goes beyond relaxation. Swedish massage is exceptionally beneficial for increasing the level of oxygen in the blood, decreasing muscle toxins,improving circulation and flexibility while easing tension a massage precisely for the purposes of rest and relaxation. Range of movement, ease minor muscle pain, and promote wellness and relaxation.",
  },
  {
    id: 1,
    image: img5,
    title: "Balinese Massage",
    duration: "60/90",
    description:
      "BALINESE MASSAGE The traditional massage of the Indonesian island of Bali helps reduce stress, stimulates the flow of blood, relieves strained muscles, helps detoxify the body, nourishes and softens the skin.",
  },
  {
    id: 1,
    image: img6,
    title: "Deep Tissue Massage",
    duration: "60/90",
    description:
      "Deep tissue massage is a massage technique that's mainly used to treat musculoskeletal issues, such as strains and sports injuries. It involves applying sustained pressure using slow, deep strokes to target the inner layers of your muscles and connective tissues. This helps to break up scar tissue that forms following an injury and reduce tension in muscle and tissue.",
  },
];
