import React from "react";
import EnquireButton from "../general/reusable/EnquireButton";
import { styled } from "styled-components";
import scrub from "../../components/assets/images/img/Asset 36.png";
import Store from "../Context/Store";
function Scrub() {
  const changeModalState = Store((state) => state.changeModalState);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <ImageContainer>
            <img src={scrub} alt="" />
          </ImageContainer>
          <Details>
            <Title>Almond body scrub</Title>
            <Duration>Duration: 30 Minutes</Duration>
            <Description>
              Almond has healing and repairing properties which helps skin
              recover from damage. Almond deep cleanses skin to encourage
              flawless acne-free radiant skin. Due to these amazing properties
              and skin benefits almond scrub makes an excellent body scrub
              agent.
            </Description>
            <EnquireButton submitFunction={changeModalState} type="pink" />
          </Details>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Scrub;

const Container = styled.div`
  padding: 60px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  width: 50%;
  margin-right: 80px;
  @media all and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;
const Details = styled.div`
  width: 30%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.h2`
  color: var(--brown);
  font-family: "source_medium";
  font-size: 30px;
`;
const Duration = styled.div`
  font-family: "source_medium";
  color: #2f2f2f;
  font-size: 20px;
  margin-bottom: 10px;
`;
const Description = styled.p`
  color: #272525;
`;
