import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { styled } from "styled-components";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2";

function Contact() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  // success alert
  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success!",
      text: "Will contact you soon.",
      icon: "success",
      confirmButtonColor: "#F482B1",
      confirmButtonText: "OK",
    });
  };

  // send email
  const handleEmail = (e) => {
    if (name && email) {
      e.preventDefault();
      const data = {
        from_name: name,
        to_name: email,
        message: phone,
      };
      emailjs.send(
        "service_em33bkb",
        "template_16wtufv",
        data,
        "WmjO4sLHUF0v2oy_D"
      );
      setEmail("");
      setName("");
      setPhone("");
      showSuccessAlert();
    } else {
      e.preventDefault();
    }
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Contact</Title>
          <Form onSubmit={(e) => handleEmail(e)}>
            <Input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              type="number"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Submit type="submit" value="SUBMIT" />
          </Form>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Contact;

const Container = styled.div`
  padding: 60px 0 80px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Title = styled.h3`
  text-align: center;
  font-size: 26px;
`;
const Form = styled.form`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const Input = styled.input`
  background-color: #f4f4f4;
  height: 50px;
  border: none;
  width: 20%;
  outline: none;
  padding-left: 20px;
  @media all and (max-width: 480px) {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
`;
const Submit = styled.input`
  background-color: #f482b1;
  height: 50px;
  width: 200px;
  outline: none;
  cursor: pointer;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
