import React from "react";
import { styled } from "styled-components";
import glass from "../../components/assets/images/img/glass.png";

function About() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Anyaʼs Wellness Spa</Title>
          <Decription>
            The Spa is an entirely novel concept. Our Therapies harness the
            healing powers of nature augmented with cutting edge to open a way
            to greater well-being. Using the holistic and gently persuasive
            power of forest essence oil and plants to heal the body and mind
          </Decription>
          <Sub>
            The benefits are remarkable. Our approach to health, beauty and
            wellbeing
          </Sub>

          <GridItem>
            <LeftContainer>
              <img src={glass} alt="" />
            </LeftContainer>
            <Cards>
              <Card>
                Forest Essentials massage oils have a base of pure cold pressed
                unrefined Sesame Oil, which has been naturally processed so that
                they are nutrient rich. These oils are blended with pure
                essential oils, which add their own special properties. Massage
                tones, relaxes and nourishes skin and muscle tissue.
              </Card>
              <Card>
                Aroma oil, is a common type of essential oil used in
                aromatherapy that can offer a variety of health benefits,
                including helping relieve chronic stress and anxiety, reducing
                pain and inflammation, and boosting immunity
              </Card>
              <Card>
                facial, The benefits of oxygen facial includes healing issues
                such as acne, wrinkles, and blemishes and it makes your skin
                younger-looking and glowing.
              </Card>
            </Cards>
          </GridItem>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default About;

const Container = styled.div`
  padding: 60px 0;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Title = styled.div`
  color: var(--brown);
  font-family: "source_medium";
  text-align: center;
  font-size: 38px;
  @media all and (max-width: 400px) {
    font-size: 32px;
  }
`;
const Decription = styled.p`
  color: #272828;
  text-align: center;
  line-height: 28px;
`;
const Sub = styled.p`
  color: #424242;
  text-align: center;
  line-height: 28px;
`;
const GridItem = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 20px;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
`;
const LeftContainer = styled.div`
  width: 28%;
  img {
    height: 100%;
  }
  @media all and (max-width: 980px) {
    width: 45%;
    margin: 0 auto;
  }
  @media all and (max-width: 400px) {
    width: 70%;
  }
`;
const ImageContainer = styled.div``;
const Cards = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const Card = styled.div`
  background-color: #fee9c0;
  margin-bottom: 20px;
  color: #424242;
  line-height: 28px;
  font-size: 18px;
  padding: 40px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }
`;
