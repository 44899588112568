import React from "react";
import { styled } from "styled-components";
import logo from "../assets/images/logo-color.png";
import phone from "../assets/images/icons/Call.svg";

function Header() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Logo>
            <img src={logo} alt="logo" />
          </Logo>
          <Contact>
            <Icon>
              <IconContainer>
                <img src={phone} alt="" />
              </IconContainer>
            </Icon>
            <Num>
              <a href="tel:+91 9526 813 000">+91 9526 813 000</a>
            </Num>
          </Contact>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  padding: 20px 0;
`;
const Logo = styled.div`
  width: 18%;
  @media all and (max-width: 640px) {
    width: 35%;
  }
  @media all and (max-width: 400px) {
    width: 44%;
  }
`;
const Contact = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Icon = styled.div`
  width: 60px;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fee9c0;
  @media all and (max-width: 480px) {
    width: 45px;
  }
  @media all and (max-width: 400px) {
    display: none;
  }
`;
const Num = styled.h1`
  background-color: #fee9c0;
  height: 50px;
  border: 1px solid #fee9c0;
  width: 200px;
  font-size: 20px;
  display: flex;
  font-family: "source_bold";
  justify-content: center;
  align-items: center;
  a {
    color: #5c260c;
    text-decoration: none;
  }
  @media all and (max-width: 480px) {
    width: 150px;
    font-size: 16px;
  }
`;
const Wrapper = styled.div``;
const IconContainer = styled.div`
  width: 15px;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
