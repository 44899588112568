import React from "react";
import EnquireButton from "../general/reusable/EnquireButton";
import { styled } from "styled-components";
import facials from "../assets/images/img/Asset 37.png";
import Store from "../Context/Store";

function Facials() {
  const changeModalState = Store((state) => state.changeModalState);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Facials</Title>
          <ImageContainer>
            <img src={facials} alt="image" />
          </ImageContainer>
          <Courses>
            <Item>
              <CoverItem>
                <ItemTitle>Anti-ageing</ItemTitle>
                <Duration>Duration: 60 Minutes</Duration>
                <Description>
                  Anti-Ageing Cream SPF 60. ... Get ultimate youth glow with
                  this light weight formula of Agelock SPF 60 helps in
                  protecting dull matured skin while nourishing it. ... 2 in1
                  formula of anti ageing and skin lightening..
                </Description>
              </CoverItem>
              <Cover>
                <EnquireButton type="pink" submitFunction={changeModalState} />
              </Cover>
            </Item>
            <Line></Line>
            <Item>
              <CoverItem>
                <ItemTitle>Skin Brightening</ItemTitle>
                <Duration>Duration: 60 Minutes</Duration>
                <Description>
                  Almond has healing and repairing properties which helps skin
                  recover from damage. Almond deep cleanses skin to encourage
                  flawless acne-free radiant skin. Due to these amazing
                  properties and skin benefits almond scrub makes an excellent
                  body scrub agent.
                </Description>
              </CoverItem>
              <Cover>
                <EnquireButton type="pink" submitFunction={changeModalState} />
              </Cover>
            </Item>
          </Courses>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Facials;

const Container = styled.div`
  padding: 60px 0;
  background-color: #f4f4f4;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Cover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.h3`
  text-align: center;
  font-size: 26px;
`;
const ImageContainer = styled.div``;
const Courses = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const CoverItem = styled.div``;
const Item = styled.div`
  width: 30%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:first-child {
    margin-right: 150px;
    @media all and (max-width: 980px) {
      margin-right: 90px;
    }
    @media all and (max-width: 768px) {
      margin-right: 0;
    }
  }
  &:last-child {
    margin-left: 150px;
    @media all and (max-width: 980px) {
      margin-left: 90px;
    }
    @media all and (max-width: 768px) {
      margin-left: 0;
      margin-bottom: 0;
    }
  }
  @media all and (max-width: 768px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
const Description = styled.p`
  color: #272525;
`;
const Line = styled.div`
  width: 1px;
  background-color: #f482b1;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const ItemTitle = styled.h2`
  color: var(--brown);
  font-family: "source_medium";
  font-size: 30px;
  margin: 0;
`;
const Duration = styled.div`
  font-family: "source_medium";
  color: #2f2f2f;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
`;
