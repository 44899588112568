import React from "react";
import logo from "../assets/images/logo-white.png";
import phone from "../assets/images/icons/Phone.svg";
import email from "../assets/images/icons/Mail.svg";
import address from "../assets/images/icons/Location.svg";
import face from "../assets/images/icons/FB.svg";
import insta from "../assets/images/icons/Insta.svg";
import x from "../assets/images/icons/X.svg";
import { styled } from "styled-components";
import accoLogo from "../../components/assets/images/accolades_new_logo.png";

function Footer() {
  return (
    <>
      <Container>
        <Wrapper className="wrapper">
          <Content>
            <Logo>
              <img src={logo} alt="image" />
            </Logo>
            <RightSection>
              <Get>
                <Title>Get in Touch</Title>
                <Cover>
                  <Icon>
                    <img src={phone} alt="" />
                  </Icon>
                  <CoverNumber>
                    <Number href="tel:+91 95268 13 000">
                      +91 95268 13 000
                    </Number>
                    <Number href="tel:+91 95268 23 000">
                      +91 95268 23 000
                    </Number>
                  </CoverNumber>
                </Cover>
                <Cover>
                  <Icon>
                    <img src={email} alt="" />
                  </Icon>
                  <Number href="mailto:narayandhakal2002@gmail.com">
                    narayandhakal2002@gmail.com
                  </Number>
                </Cover>
              </Get>
              <Address>
                <Cover>
                  <Icon>
                    <img src={address} alt="" />
                  </Icon>
                  <Para>
                    Anya's wellness Spa,
                    <br /> CRA 201 CHAKKUNGAL road,
                    <br /> Palarivattom, kochi 682025
                  </Para>
                </Cover>
              </Address>
              <Social>
                <Title>Follow us on</Title>
                <CoverIcons>
                  <Icons href="https://www.facebook.com/tase.asu?mibextid=ZbWKwL">
                    <img src={face} alt="image" />
                  </Icons>
                  <Icons href="https://www.instagram.com/kevisunu/">
                    <img src={insta} alt="image" />
                  </Icons>
                </CoverIcons>
              </Social>
            </RightSection>
          </Content>
        </Wrapper>
      </Container>
      <Banner>
        <span>Copyright © 2024 Anyas Wellness</span>
        <Logos>
          <a href="https://accoladesintegrated.com/">
            <img src={accoLogo} alt="" />
          </a>
        </Logos>
      </Banner>
    </>
  );
}

export default Footer;

const Container = styled.div`
  padding: 60px 0;
  position: relative;
  background-color: #353535;
`;
const CoverNumber = styled.div``;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Logos = styled.div`
  width: 120px;
  margin-left: 10px;
  a {
    display: block;
    width: 85px;
  }
  img {
    width: 100%;
    display: block;
  }
`;
const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 30px;
  background: #404040;

  span {
    color: #fff;
    font-size: 14px;
  }
`;
const Logo = styled.div`
  width: 25%;
  @media all and (max-width: 480px) {
    width: 70%;
    margin-bottom: 30px;
  }
`;
const RightSection = styled.div`
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 60%;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Get = styled.div``;
const Title = styled.div`
  margin-bottom: 20px;
  font-size: 22px;
`;
const Cover = styled.div`
  display: flex;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const Icon = styled.div`
  width: 30px;
  margin-right: 10px;
`;
const Number = styled.a`
  font-size: 18px;
  color: #fff;
  display: block;
  text-decoration: none;
`;
const Address = styled.div`
  margin-top: 30px;
`;
const Para = styled.div``;
const Social = styled.div`
  @media all and (max-width: 768px) {
    margin-top: 30px;
  }
`;
const CoverIcons = styled.div`
  display: flex;
`;
const Icons = styled.a`
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;
