import React from "react";
import img1 from "../assets/images/img/Asset 40.png";
import img2 from "../assets/images/img/Asset 39.png";
import img3 from "../assets/images/img/Asset 38.png";
import { styled } from "styled-components";

function Relaxation() {
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Title>Relaxation 30 Minutes</Title>
          <Cards>
            <Card>
              <ImageContainer>
                <img src={img1} alt="image" />
              </ImageContainer>
              <SubTitle>Back Massage</SubTitle>
            </Card>
            <Card>
              <ImageContainer>
                <img src={img2} alt="image" />
              </ImageContainer>
              <SubTitle>Head and Shoulder</SubTitle>
            </Card>
            <Card>
              <ImageContainer>
                <img src={img3} alt="image" />
              </ImageContainer>
              <SubTitle>Foot Massage</SubTitle>
            </Card>
          </Cards>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Relaxation;

const Container = styled.div`
  padding: 60px;
  background: rgb(183, 147, 73);
  background: linear-gradient(
    93deg,
    rgba(183, 147, 73, 1) 0%,
    rgba(93, 39, 13, 1) 100%
  );
  color: #fff;
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const Card = styled.div`
  margin-right: 80px;
  &:last-child {
    margin-right: 0;
    @media all and (max-width: 640px) {
      margin: 0 auto;
    }
  }
  @media all and (max-width: 640px) {
    margin-right: 0;
    width: 60%;
    margin: 0 auto;
  }
  @media all and (max-width: 400px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  border: 2px dotted #fff;
  border-radius: 50%;
  padding: 15px;
`;
const Title = styled.h1`
  font-size: 36px;
  font-family: "source_medium";
  text-align: center;
`;
const Cards = styled.div`
  display: flex;
  justify-content: center;
  @media all and (max-width: 640px) {
    flex-direction: column;
  }
`;
const SubTitle = styled.h2`
  text-align: center;
  margin-top: 20px;
`;
