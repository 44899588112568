import React from "react";
import EnquireButton from "../general/reusable/EnquireButton";
import { styled } from "styled-components";
import { type_of_massage } from "../general/array";
import Store from "../Context/Store";

function Massages() {
  const changeModalState = Store((state) => state.changeModalState);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Cards>
            {type_of_massage.map((item) => (
              <Card>
                <CoverItem>
                  <ImageContainer>
                    <img src={item.image} alt={item.title} />
                  </ImageContainer>
                  <CoverText>
                    <Title> {item.title} </Title>
                    <Duration>Duration: {item.duration} Minutes</Duration>
                    <Description>{item.description} </Description>
                  </CoverText>
                </CoverItem>
                <Cover>
                  <EnquireButton
                    submitFunction={changeModalState}
                    type="pink"
                  />
                </Cover>
              </Card>
            ))}
          </Cards>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Massages;

const Container = styled.div`
  background-color: #f4f4f4;
  padding: 60px 0;
`;
const Wrapper = styled.div``;
const CoverItem = styled.div``;
const Cover = styled.div`
  display: flex;
  justify-content: center;
`;
const CoverText = styled.div`
  background-color: #fff;
  padding: 20px 30px;
`;
const Content = styled.div``;
const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
`;
const Card = styled.div`
  width: 30%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  @media all and (max-width: 1080px) {
    width: 48%;
  }
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
`;
const Title = styled.div`
  color: var(--brown);
  font-family: "source_medium";
  font-size: 26px;
  /* margin-bottom: 10px; */
`;
const Duration = styled.div`
  font-family: "source_medium";
  color: #2f2f2f;
  font-size: 18px;
  margin-bottom: 10px;
`;
const Description = styled.p`
  color: #272525;
  font-size: 15px;
`;
