import React from "react";
import EnquireButton from "../general/reusable/EnquireButton";
import { styled } from "styled-components";
import bg from "../../components/assets/images/img/Banner.jpg";
import mob_bg from "../../components/assets/images/img/Banner_Mob.jpg";
import Store from "../Context/Store";
function Spotlight() {
  const changeModalState = Store((state) => state.changeModalState);
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <TextContainer>
            <Title>
              Experience the soothing <br />
              pulse of relaxation
            </Title>
            <EnquireButton submitFunction={changeModalState} />
          </TextContainer>
        </Content>
      </Wrapper>
      <Whatsapp href="https://wa.me/+919910876271">
        <i class="ri-whatsapp-fill"></i>
      </Whatsapp>
      <Phone href="tel:+919526813000">
        <i class="ri-phone-fill"></i>
      </Phone>
    </Container>
  );
}

export default Spotlight;

const Container = styled.div`
  height: calc(100vh - 125px);
  position: relative;
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media all and (max-width: 1280px) {
    background-position: bottom;
  }
  @media all and (max-width: 1080px) {
    background-position: right;
    align-items: flex-end;
  }
  @media all and (max-width: 640px) {
    background: url(${mob_bg});
    background-position: right;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div``;
const TextContainer = styled.div`
  @media all and (max-width: 1080px) {
    margin-bottom: 50px;
  }
`;
const Title = styled.div`
  color: #fff;
  font-family: "source_medium";
  font-size: 45px;
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    font-size: 34px;
  }
`;
const Whatsapp = styled.a`
  color: #25d366;
  font-size: 35px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 10000;
  padding: 10px;
  text-decoration: none;
  position: fixed;
  cursor: pointer;
  border-radius: 50%;
  bottom: 50px;
  right: 50px;
  @media all and (max-width: 480px) {
    height: 30px;
    width: 30px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const Phone = styled.a`
  color: #002147;
  text-decoration: none;
  left: 30px;
  bottom: 50px;
  position: fixed;
  z-index: 10000;
  font-size: 35px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  padding: 10px;
  /* display: none; */
  @media all and (max-width: 480px) {
    height: 30px;
    width: 30px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
