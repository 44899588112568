import React from "react";
import { styled } from "styled-components";

function EnquireButton({ type, submitFunction }) {
  return (
    <Container type={type} onClick={() => submitFunction()}>
      <Text type={type}>Enquire Now</Text>
    </Container>
  );
}

export default EnquireButton;

const Container = styled.div`
  width: 230px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ type }) => (type == "pink" ? "#F482B1" : " #fff")};
  cursor: pointer;
  @media all and (max-width: 480px) {
    height: 50px;
    width: 200px;
  }
`;
const Text = styled.h3`
  color: ${({ type }) => (type == "pink" ? "#fff" : " #5c260c")};
  font-size: 24px;
  font-family: "source_medium";
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
