import React from "react";
import Header from "../includes/Header";
import Spotlight from "../includes/Spotlight";
import About from "../includes/About";
import Massages from "../includes/Massages";
import Relaxation from "../includes/Relaxation";
import Scrub from "../includes/Scrub";
import Facials from "../includes/Facials";
import Contact from "../includes/Contact";
import Footer from "../includes/Footer";
import EnquiryModal from "../general/modal/EnquiryModal";

function Home() {
  return (
    <>
      <Header />
      <Spotlight />
      <About />
      <Massages />
      <Relaxation />
      <Scrub />
      <Facials />
      <Contact />
      <Footer />
      <EnquiryModal />
    </>
  );
}

export default Home;
