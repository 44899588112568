import "./App.css";
import Home from "./components/screens/Home";
import "./components/assets/css/style.css";
import "remixicon/fonts/remixicon.css";

function App() {
  return <Home />;
}

export default App;
